.App-toolbar {
    background: rgba(147, 128, 108, 0.1);
    /*padding-left: 24px;*/
    /*text-align: left;*/
    display: flex;
    align-items: center;
}

.App-toolbar-units {
    height:40px;
    padding-left: 12px;
    padding-right: 12px;
    background-size: 100%;
    background-color: transparent;
    border: none;
    font-size: larger;
    font-weight: bold;
}

.App-toolbar-units:hover {
    background: rgba(147, 128, 115, 0.1);
}

.App-toolbar-units::before {
    content: "Units:";
    opacity: 0.5;
    margin-right: 5px;
}

/*.App-toolbar button {*/
    /*height:40px;*/
    /*padding-left: 12px;*/
    /*padding-right: 12px;*/
    /*background-size: 100%;*/
    /*background-color: transparent;*/
    /*border: none;*/
/*}*/

/*.App-toolbar button:hover {*/
    /*background: rgba(147, 128, 115, 0.1);*/
/*}*/

.DrawerButton {
    display: none;
}

@media only screen and (max-width: 415px) {
    .App-toolbar {
        overflow: auto;
        max-width: 360px;
    }

    .App-toolbar-units {
        height:30px;
        font-size: inherit;
        font-weight: bold;
    }

    .DrawerButton {
        display: inherit;
    }
}
