.App {
    text-align: center;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    overflow: hidden;
    /*margin: 0 2%;*/
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
