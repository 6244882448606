/*.App-layersReducer {*/
    /*!* put the layer list on the left *!*/
    /*!*list-style-type:none;*!*/
    /*!* 12em is the width of the columns *!*/
    /*flex: 0 0 13em;*/
    /*flex-grow: 0;*/
    /*order: -1;*/
    /*background: rgba(147, 128, 108, 0.1);*/
    /*overflow-y: auto;*/
    /*padding-left: 0px;*/
    /*margin-top: 0;*/
    /*margin-bottom: 0;*/
/*}*/

/*.App-layersReducer ul {*/
    /*padding:0;*/
    /*overflow:auto;*/
/*}*/

/*@media only screen and (max-width: 415px) {*/
    .App-layers {
        display: none;
    }

    .App-layers-displayed {
        position: fixed;
        left: 26px;
        top: 110px;
        text-align: left;
        height: 65%;
        max-width: 70%;
        max-height: 70%;
        min-width: 200px;
        overflow-y: auto;
        /*width: 280px;*/
        background-color: rgba(255,255,255,0.9);
        border: 1px solid darkgrey;
        /*padding: 32px 16px;*/
        box-sizing: border-box;
        z-index: 10;
        animation-name: fadeIn;
        animation-duration: 1s;
    }

    .App-layers-displayed ul {
        padding: 0 4px;
        margin-block-start: 4px;
        margin-block-end: 4px;
    }
/*}*/

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
