.App-header {
    background-color: #222;
    color: white;
    flex: none;
/*
    display: flex;
    align-items: center;
    justify-content: left;
*/
}

.App-header-documents-page {
    /*background-color: #222;*/
    /*background: rgba(147, 128, 108, 0.8);*/
    /*min-height: 100vh;*/
    display: flex;
    /*flex-direction: column;*/
    align-items: center;
    justify-content: left;
    border-bottom: 1px solid black;
    /*font-size: calc(10px + 2vmin);*/
    /*color: white;*/
    /*flex: none;*/
}

.App-header span,
.App-header-documents-page span {
    font-weight: bolder;
    color: #FF954A;
    position: absolute;
    right: 15%;
}

@media only screen and (max-width: 415px) {
    .App-header span,
    .App-header-documents-page span {
        display: none;
    }
}
