.Layer-edit-form {

}

.Layer-edit-form article {
    display: flex;
    flex-direction: row;
    margin-top: 0.4em;
    margin-bottom: 0.4em;
}

.Layer-edit-form label {
    flex: 1;
}

.Layer-edit-form input, textarea {
    flex: 5;
    outline: none;
    border: 1px solid #ccc;
    background-color: white;
    font: inherit;
    padding: 6px 10px;
    display: block;
    width: 100%;
    box-sizing: border-box;
}

.Layer-edit-form button {
    float: right;
    height: 24px;
    border-radius: 5px;
    margin-left: 1em;
    margin-right: 1em;
    margin-top: 0.4em;
}

