.Layer {
    padding: 4px;
    border-bottom: 1px inset rgba(147, 128, 108, 0.1);
    display: flex;
    flex-direction: row;
    tab-index: 1;
    z-index: -1;
    margin-top: 0;
    margin-bottom: 0;
    /*margin-right: 2px;*/
    /*background-color: aliceblue;*/
}

.Layer h4 {
    margin-top: auto;
    margin-bottom: auto;
    text-align: left;
}

.Layer button {
    color: grey;
    border: none;
    opacity: 0.4;
}

.Layer button:hover, .Layer button:active {
    opacity: 1.0;
}

.LayerSelectionBox {
    /*flex: 2;*/
    marginRight: 3;
    /*border: 1px solid black;*/
}

.LayerSelectionMark {
    width: 16px;
    marginLeft: 2px;
    cursor: default;
    font-weight: bolder;
}

.LayerNameBox {
    flex: 8;
    cursor: default;
    padding: 3px;
}

.Layer-displayed {
    /*background-color: aquamarine;*/
}

.Layer-undisplayed {
    /*background-color: aliceblue;*/
}
