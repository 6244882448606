.UploadPopup {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    text-align: center;
    align-items: center;
    animation-name: fadeIn;
    animation-duration: 2s;
    padding-bottom: 0.6em;
}

.UploadPopup h2 {
    opacity: 0.4;
}

.UploadPopup button {
    height: 10%;
    font-size: 16px;
    padding: 8px 12px;
    border-radius: 5px;
}

.UploadTarget {
    border: 1px dashed;
    padding: 60px 30px;
    text-align: center;
}

.UploadTargetDragLeave {
    background-color: white;
}

.UploadTargetDragOver {
    background-color: lightgrey;
}

.UploadTarget span {
    opacity: 0.2;
    font-size: 3em;
}

.UploadTarget p {
    text-align: left;
    overflow: auto;
    white-space: pre-line;
    padding: 0;
    margin-block-start: 0;
    margin-block-end: 0;
    animation-name: fadeIn;
    animation-duration: 3s;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
