.EditorComponent {
    flex: auto;
    /*display: flex;*/
    /*flex-direction: column;*/
    /*height: 100%;*/
    /*min-height: 100%;*/
/*
    border-left: 1.5em solid rgba(147, 128, 108, 0.1);
    border-right: 3em solid rgba(147, 128, 108, 0.1);
    border-bottom: 1.5em solid rgba(147, 128, 108, 0.1);
 */
}

.EditorComponent canvas {
}

