.ButtonSeparator {
    height:40px;
    padding-left: 6px;
    padding-right: 6px;
    background-size: 100%;
    background-color: transparent;
    border: none;
}

.IconSeparator {
    color: white;
    position: relative;
    top: 3px;
}

@media only screen and (max-width: 415px) {
    .ButtonSeparator {
        /*display: none;*/
    }
}