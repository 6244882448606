.DisplayCoordinatesTool {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: white;
    opacity: 0.8;
}
.DisplayCoordinatesTool h5 {
    margin: 0px;
    padding: 3px;
}
