.VertexLabel {
    position: absolute;
    animation: fadeIn 2s;
    /*animation-name: fadeIn;*/
    /*animation-delay: 5s;*/
    /*animation-duration: 5s;*/
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
