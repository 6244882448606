.Snackbar {
    background-color: ghostwhite;
    position: absolute;
    top: 15%;
    right:20%;
    text-align: center;
    padding: 0.25em;
    border: none;
    border-radius: 5px;
    font-size: 20px;
    color: black;
    box-sizing: border-box;
    z-index: 75;
    animation: fadeInOut 5s;
}

.Snackbar h3 {
    margin: 0;
}

@keyframes fadeInOut {
    0% { opacity: 0; }
    25% { opacity: 1 }
    75% { opacity: 1; }
    100% { opacity: 0; }
}
