.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden;
  /*margin: 0 2%;*/
}

/*.App-body {*/
/*  display: flex;*/
/*  flex:1 0 auto;*/
/*  border-left: 1.5em solid rgba(147, 128, 108, 0.1);*/
/*}*/

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
